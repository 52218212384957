import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged,
    signOut,
    User
} from 'firebase/auth';

// Interface for vote data
interface VoteData {
    trek_votes: number;
    wars_votes: number;
    recent_votes: {
        uid: string;
        email: string;
        candidate: string;
        time_cast: string;
    }[];
    elapsed: number;
}

// Replace with your Firebase config
const firebaseConfig = {
    apiKey: "AIzaSyCBQxeJpDTbAL1CfrSlM8Ilxwgcb48IqgM",
    authDomain: "gruff1-382819.firebaseapp.com",
    projectId: "gruff1-382819",
    storageBucket: "gruff1-382819.appspot.com",
    messagingSenderId: "189291661168",
    appId: "1:189291661168:web:889d2e641c6e31299eb1b5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const VotingApp: React.FC = () => {
    const [trekVotes, setTrekVotes] = useState<number>(0);
    const [warsVotes, setWarsVotes] = useState<number>(0);
    const [isVoting, setIsVoting] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    // Fix: Properly type the user state to accept User or null
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [recentVotes, setRecentVotes] = useState<VoteData['recent_votes']>([]);
    const [isLoadingVotes, setIsLoadingVotes] = useState<boolean>(false);

    // Fetch initial vote data
    const fetchVoteData = async () => {
        setIsLoadingVotes(true);
        try {
            const response = await fetch('https://app.tanngrisnir.io/api/votes/report');
            if (response.ok) {
                const data: VoteData = await response.json();
                setTrekVotes(data.trek_votes);
                setWarsVotes(data.wars_votes);
                setRecentVotes(data.recent_votes.slice(0, 5)); // Get only the 5 most recent votes
            } else {
                console.error('Failed to fetch vote data:', response.status);
            }
        } catch (error) {
            console.error('Error fetching vote data:', error);
        } finally {
            setIsLoadingVotes(false);
        }
    };

    // Monitor authentication state and fetch votes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser); // Now TypeScript knows this is acceptable
            setLoading(false);
        });

        // Fetch initial vote data
        fetchVoteData();

        // Cleanup subscription
        return () => unsubscribe();
    }, []);

    const handleLogin = async () => {
        try {
            await signInWithPopup(auth, provider);
            setMessage('Successfully logged in!');
        } catch (error) {
            const authError = error as { message: string };
            setMessage(`Login error: ${authError.message}`);
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setMessage('Successfully logged out');
        } catch (error) {
            const authError = error as { message: string };
            setMessage(`Logout error: ${authError.message}`);
        }
    };

    const castVote = async (candidate: string) => {
        if (!user) {
            setMessage('Please log in to vote');
            return;
        }

        setIsVoting(true);
        setMessage('');

        try {
            // Get the current user's ID token
            const token = await user.getIdToken();

            const response = await fetch('https://app.tanngrisnir.io/api/votes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    candidate,
                    userId: user.uid,
                    email: user.email
                }),
            });

            if (response.ok) {
                if (candidate === 'Star Trek') {
                    setTrekVotes(prevVotes => prevVotes + 1);
                } else {
                    setWarsVotes(prevVotes => prevVotes + 1);
                }
                setMessage(`Vote for ${candidate} recorded successfully!`);

                // Refresh vote data after voting
                await fetchVoteData();
            } else {
                // Parse the error response to get the status message
                const errorData = await response.json();
                setMessage(`Error: ${errorData.status || response.statusText}`);
            }
        } catch (error) {
            const voteError = error as { message: string };
            setMessage(`Error: ${voteError.message}`);
        } finally {
            setIsVoting(false);
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
                <div className="text-xl">Loading authentication...</div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white p-4">
            <h1 className="text-3xl font-bold mb-4">Star Trek VS Star Wars</h1>

            {/* Authentication UI */}
            <div className="mb-6 p-4 bg-gray-800 rounded-lg w-full max-w-4xl">
                {user ? (
                    <div className="flex flex-col sm:flex-row items-center justify-between">
                        <div className="flex items-center mb-4 sm:mb-0">
                            <img
                                src={user.photoURL || undefined}
                                alt="Profile"
                                className="w-10 h-10 rounded-full mr-3"
                            />
                            <div>
                                <div className="font-semibold">{user.displayName}</div>
                                <div className="text-sm text-gray-300">{user.email}</div>
                            </div>
                        </div>
                        <button
                            onClick={handleLogout}
                            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Logout
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-col sm:flex-row items-center justify-between">
                        <div className="mb-4 sm:mb-0">Please sign in to vote</div>
                        <button
                            onClick={handleLogin}
                            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                        >
                            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
                                />
                            </svg>
                            Sign in with Google
                        </button>
                    </div>
                )}
            </div>

            <div className="flex flex-col md:flex-row items-center justify-around w-full max-w-4xl">
                {/* Star Trek Side - Kirk's Gold Uniform */}
                <div className="flex flex-col items-center mb-8 md:mb-0">
                    <div className="bg-red-500 rounded-full p-4 mb-4 h-48 w-48 flex items-center justify-center">
                        <div className="text-5xl font-bold text-center text-gray-900"><img src="/icons8-star-trek-symbol-240.svg"/></div>
                    </div>
                    <div className="text-xl mb-2">Votes: {trekVotes}</div>
                    <button
                        onClick={() => castVote('Star Trek')}
                        disabled={isVoting || !user}
                        className="bg-red-500 hover:bg-red-700 text-black font-bold py-2 px-6 rounded-full disabled:opacity-80"
                    >
                        {isVoting ? 'Voting...' : 'Vote Star Trek'}
                    </button>
                </div>

                <div className="text-2xl font-bold my-4 md:my-0 md:mx-4">VS</div>

                {/* Star Wars Side - Luke's Blue Lightsaber */}
                <div className="flex flex-col items-center">
                    <div className="bg-sky-500 rounded-full p-4 mb-4 h-48 w-48 flex items-center justify-center">
                        <div className="text-5xl font-bold text-center"><img src="/Emblem_of_the_Galactic_Republic.svg"/></div>
                    </div>
                    <div className="text-xl mb-2">Votes: {warsVotes}</div>
                    <button
                        onClick={() => castVote('Star Wars')}
                        disabled={isVoting || !user}
                        className="bg-sky-500 hover:bg-sky-700 text-black font-bold py-2 px-6 rounded-full disabled:opacity-80"
                    >
                        {isVoting ? 'Voting...' : 'Vote Star Wars'}
                    </button>
                </div>
            </div>

            {message && (
                <div className={`mt-8 p-3 rounded ${message.includes('Error') ? 'bg-red-800' : 'bg-green-800'}`}>
                    {message}
                </div>
            )}

            {/* Recent Votes Feed */}
            <div className="mt-12 w-full max-w-4xl">
                <h2 className="text-2xl font-bold mb-4">Recent Votes</h2>

                {isLoadingVotes ? (
                    <div className="text-center p-4">Loading recent votes...</div>
                ) : (
                    <div className="bg-gray-800 rounded-lg overflow-hidden">
                        <table className="w-full">
                            <thead>
                            <tr className="border-b border-gray-700">
                                <th className="px-6 py-3 text-left">User</th>
                                <th className="px-6 py-3 text-left">Vote</th>
                                <th className="px-6 py-3 text-left">Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            {recentVotes.length > 0 ? (
                                recentVotes.map((vote, index) => (
                                    <tr key={index} className="border-b border-gray-700">
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm">{vote.email.split('@')[0]}@...</div>
                                        </td>
                                        <td className="px-6 py-4">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            vote.candidate === 'Star Trek'
                                ? 'bg-amber-100 text-amber-800'
                                : 'bg-sky-100 text-sky-800'
                        }`}>
                          {vote.candidate}
                        </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            {new Date(vote.time_cast).toLocaleString()}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={3} className="px-6 py-4 text-center">
                                        No votes recorded yet
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}

                <div className="mt-4 flex justify-end">
                    <button
                        onClick={fetchVoteData}
                        disabled={isLoadingVotes}
                        className="bg-gray-700 hover:bg-gray-600 text-white text-sm py-2 px-4 rounded"
                    >
                        {isLoadingVotes ? 'Refreshing...' : 'Refresh Votes'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VotingApp;