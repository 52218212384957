import React from 'react';
import VotingApp from './VotingApp';

function App() {
  return (
      <div className="App">
        <VotingApp />
      </div>
  );
}

export default App;